import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '../models/jwt-payload.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private key = 'token';
  private entraToken = 'entra-token';

  constructor() {}

  getTokenEntra(): string | null {
    return localStorage.getItem(this.entraToken);
  }

  removeTokenEntra() {
    localStorage.removeItem(this.entraToken);
  }

  setTokenEntra(token: string) {
    localStorage.setItem(this.entraToken, token);
  }

  getToken(): string | null {
    return localStorage.getItem(this.key);
  }

  removeToken() {
    localStorage.removeItem(this.key);
  }

  setToken(token: string) {
    localStorage.setItem(this.key, token);
  }

  getJwtPayload() {
    const token = this.getToken();
    if(!token) return {} as JwtPayload;
    const decoded = jwtDecode(token) as JwtPayload;
    return decoded;
  }

  hasPermission(permission: string) {
    const token = this.getJwtPayload();    
    if (!token) {      
      return false;
    }
    
    const permissions = Array.isArray(token.Permission) ? token.Permission : [token.Permission];    
    return permissions.includes(permission);
  }

  isAdmin() {
    const jwtPayload = this.getJwtPayload();
    const roles = Array.isArray(jwtPayload.Role) ? jwtPayload.Role : [jwtPayload.Role];
    return roles.includes("Admin");
  }
}
